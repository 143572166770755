import { ethers } from "ethers";
// confirm this - need to make it dynammic based on contract specs - implementation
import abi from "./abi/EightyEightyMinterERC721A.json";
import paymentAbi from "./abi/paymentAbi.json";
import config from "../utils/config.json";

let contract;
let provider;
let signer;

export const initializeContract = (contractAddress) => {
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = provider.getSigner();
    contract = new ethers.Contract(contractAddress, abi, signer);
    return contract;
  } catch (err) {
    console.log(err.message);
  }
};

export const makeTransaction = async (projectId, sign) => {
  try {
    const payContract = new ethers.Contract(config.payContractAddress, paymentAbi, signer);
    const transaction = await payContract.takePayment(projectId, config.productName, sign, { value: sign[1] });
    return await transaction.transactionait();
  } catch (err) {
    console.log(err);
  }
};

export const pauseMint = async () => {
  return await contract.paused();
};

export const isRevealed = async () => {
  return await contract.revealed();
};

export const contractBalance = async (contractAddress) => {
  const res = await provider.getBalance(contractAddress);
  return res.toString();
};

export const togglePause = async () => {
  return await contract.togglePause();
};

export const setSaleConditions = async (saleConditions) => {
  return await contract.setSaleConditions(saleConditions, false);
};

export const withdraw = async () => {
  return await contract.withdraw();
};
