import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import { toast } from "react-toastify";
import ArrowRight from "../../../../assets/images/arrowRight.png";
import { AllowlistApiCall, ApiCall } from "../../../../utils/ApiUtils";

const BulkUpload = ({
  bulkUpload,
  bulkUploadModalClose,
  groupType,
  groupId,
  allowlistId,
  setState,
  getAddress,
  fetchData,
  getWinners,
}) => {
  const [csvfile, setCsvfile] = useState(undefined);

  const handleChange = (e) => {
    setCsvfile(e.target.files[0]);
  };

  const importCSV = async () => {
    try {
      const formData = new FormData();
      formData.append("file", csvfile);
      const response = await AllowlistApiCall("POST", `/rest/allowlist/${allowlistId}/${groupId}/csvUpload`, formData);
      getAddress();
      fetchData();
      setState(true);

      if (!response) {
        toast.error("Failed to load. Please try again later.");
      }
      bulkUploadModalClose();
      setCsvfile();
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  return (
    <Modal className="edit-modal bulk-upload" size="lg" centered show={bulkUpload} onHide={bulkUploadModalClose}>
      <Modal.Header closeButton className="border-0">
        <h4>Bulk Upload</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <div className="box">
            <section className="outputname" style={{ color: "#5A5A5A" }}>
              {csvfile ? csvfile?.name : "Upload Allowlist (.csv)"}
            </section>
            <input
              type="file"
              accept=".csv"
              onChange={(e) => {
                handleChange(e);
              }}
              className="inputfile inputfile-1"
            />
            <BiUpload />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-end border-0">
        <button onClick={importCSV} className="btn next-btn m-0">
          Done
          <span className="d-flex">
            <img src={ArrowRight} alt="Icon" />
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkUpload;
