import React, { useEffect } from "react";
import BasicForm from "./components/BasicForm";
import Header from "../../components/Header";
import PricingGroupsForm from "./components/PricingGroupsForm";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentFormStep } from "../../store/reducer";

const BasicConfig = () => {
  const dispatch = useDispatch();
  document.title = "Forge - Launch";

  const currentFormStep = useSelector(({ currentFormStep }) => currentFormStep);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goBack = (current) => {
    if (current < currentFormStep) dispatch(setCurrentFormStep(currentFormStep - 1));
  };

  const goNext = () => dispatch(setCurrentFormStep(currentFormStep + 1));

  return (
    <>
      <Header />
      <div className="comon-all-body hmepage-1 float-start w-100 mt-5">
        <div className="comon-div">
          <div className="container px-0">
            <h1 className="main-haeding text-center text-white mb-2"> Launch Mint </h1>
            <p className="d-block mx-auto text-center text-white fs-20 mb-2 futura-light">
              Launch a mint dApp with a custom sale flow below.
            </p>
            <div className="comon-from my-5">
              <div className="blur"></div>
              <div className="main-forms">
                <div className="link-gopage mb-5">
                  <ul className="list-unstyled d-flex justify-content-center align-items-center mb-1">
                    <li className={`${currentFormStep === 1 ? "active" : ""}`} onClick={() => goBack(1)}>
                      <div className="comon-btn-nomber text-center d-table">
                        <span className="count-div">1</span>
                        <p className="fs-20"> Basic Config </p>
                      </div>
                    </li>
                    <li className={`${currentFormStep === 2 ? "active" : ""}`} onClick={() => goBack(2)}>
                      <div className="comon-btn-nomber text-center d-table" style={{ minWidth: 150 }}>
                        <span className="count-div">2</span>
                        <p className="fs-20"> Pricing </p>
                      </div>
                    </li>
                  </ul>
                </div>
                {currentFormStep === 1 && <BasicForm goNext={goNext} />}
                {currentFormStep === 2 && <PricingGroupsForm goNext={goNext} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicConfig;
