import React, { useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import useFormPersist from "react-hook-form-persist";
import { useNavigate } from "react-router-dom";

import FormInput from "../../../components/FormInput";
import ArrowRight from "../../../assets/images/arrowRight.png";
import { secondStepValidation } from "../validationSchema";
import { ApiCall } from "../../../utils/ApiUtils";
import {
  setCurrentFormStep,
  setLoader,
  setSavedFirstStep,
  setSaveDraftData,
  setSkeleton,
} from "../../../store/reducer";
import DeploymentModal from "./DeploymentModal";
import { makeTransaction } from "../../../web3.js/contractIngration";

function PricingGroupsForm({ goNext }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedAllowlistGroups } = useSelector((state) => state);
  const savedFirstStep = useSelector(({ savedFirstStep }) => savedFirstStep);

  const [show, setShow] = useState(false);
  const [deploymentFee, setDeploymentFee] = useState({});
  const [cbSelect, setCbSelect] = useState(false);

  const handleClose = () => {
    setShow(false);
    setCbSelect(false);
  };

  const handleShow = async () => {
    try {
      dispatch(setSkeleton(true));
      setShow(true);
      const resp = await ApiCall("GET", `/rest/forge/getFees/${savedFirstStep.id}`);
      setDeploymentFee(resp);
      dispatch(setSkeleton(false));
    } catch (error) {
      console.log(error);
      dispatch(setSkeleton(false));
      toast.error("Something went wrong.");
    }
  };

  const methods = useForm({
    resolver: yupResolver(secondStepValidation),
    defaultValues: {
      groupList: [...selectedAllowlistGroups],
    },
    mode: "all",
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
  } = methods;
  const { fields } = useFieldArray({
    control,
    name: "groupList",
  });

  useFormPersist("pricingForm", {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const onSubmit = async (data, isDraft) => {
    try {
      const payload = data.groupList.map((item) => ({
        ...item,
      }));

      dispatch(setLoader(true));
      await ApiCall("PUT", `/rest/forge/${isDraft ? "pricing" : "launch"}/${savedFirstStep.id}`, {
        allowListGroup: payload,
      });

      dispatch(setLoader(false));

      toast.success(`${isDraft ? "Draft saved." : "Launch Successfully."}`);
      if (!isDraft) {
        localStorage.removeItem("pricingForm");
        localStorage.removeItem("basicForm");

        dispatch(setSaveDraftData(null));
        dispatch(setSavedFirstStep(null));
        dispatch(setCurrentFormStep(1));
        navigate("/manage");
      }

      goNext();
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error);
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const handleFormSubmit = async (isDraft, signature, amount) => {
    try {
      dispatch(setLoader(true));
      if (!isDraft) {
        // make backend API call first and then pass signer and value in makeTransaction function.
        const res = await makeTransaction(savedFirstStep.id, signature, amount);
        console.log("res --> ", res);
      }
      handleSubmit(onSubmit)(isDraft);
      const errr = methods.formState.errors?.groupList;
      dispatch(setLoader(false));
      for (let [key, value] of Object.entries(Array.isArray(errr) ? errr[0] || {} : {})) {
        if (value?.type === "required") {
          toast.error("Please fill all fields marked by red dots.");
          break;
        }
      }
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error);
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  return (
    <>
      <div className="comon-all-body hmepage-1 allowist-p1 new-allow-from float-start w-100">
        <div className="comon-div">
          <FormProvider {...methods}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleShow();
              }}
            >
              <div className="inside-div-cm top-allow-form col-lg-10 w-90 mb-0 mx-auto d-block">
                <h3> Pricing </h3>
                <p>Configure pricing across allowlist groups.</p>
              </div>
              <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block mb-3">
                <div className="clone-main-div">
                  <ul>
                    {fields.map((item, index) => {
                      return (
                        <li key={`groupList.${index}`}>
                          <div className="comon-opcity-div">
                            <h3>{item.groupName}</h3>
                            <h5> {"Configure the pricing for this list."} </h5>
                            <div className="show-main-register-mint">
                              <div className="comon-input-div mt-2">
                                <label>Price</label>
                                <div className="inpy-div d-flex mt-2">
                                  <FormInput
                                    name={`groupList.${index}.price`}
                                    type="text"
                                    className="mb-0 w-100"
                                    inputClassName="w-100"
                                  />
                                  <div className="slp">
                                    <span className="form-select">ETH</span>
                                  </div>
                                </div>
                              </div>
                              <div className="row my-3">
                                <div className="col-lg-6">
                                  <FormInput
                                    name={`groupList.${index}.limitPerWallet`}
                                    type="text"
                                    label={"Limit Per Wallet"}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <FormInput
                                    name={`groupList.${index}.maxAllocated`}
                                    type="text"
                                    label={"Max Allocated Supply"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block mt-5">
                <div className="d-sm-flex align-items-center justify-content-end">
                  <div className="right-pre-div d-flex align-items-center justify-content-between">
                    <button type="button" onClick={() => handleFormSubmit(true)} className="btn pre-btn">
                      Save Draft
                    </button>

                    <button type="submit" className="btn next-btn">
                      Deploy
                      <span className="d-flex">
                        <img src={ArrowRight} alt="Icon" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
      <DeploymentModal
        show={show}
        handleClose={handleClose}
        handleFormSubmit={handleFormSubmit}
        fee={deploymentFee}
        cbSelect={cbSelect}
        setCbSelect={setCbSelect}
      />
    </>
  );
}

export default PricingGroupsForm;
