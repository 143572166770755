import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import { FaInfoCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { toast } from "react-toastify";
import { ethers } from "ethers";

import Remove from "../../../../assets/images/e-remove.png";
import ArrowRight from "../../../../assets/images/arrowRight.png";
import RemovePurple from "../../../../assets/images/e-remove-purple.png";
import { AllowlistApiCall, ApiCall } from "../../../../utils/ApiUtils";
import { setLoader } from "../../../../store/reducer";
import { cutAddress } from "../../../../utils/common";
import BulkUpload from "./BulkUpload";
import { useConfirmationDialog } from "../../../../components/ConfirmationModel";

const colorStyles = {
  multiValue: (styles, { data }) => ({
    ...styles,
    borderColor: data.isValid && `#ff0000 ${" "} !important`,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.isValid && `#ff0000 ${" "} !important`,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.isValid && `#ff0000 ${" "} !important`,
  }),
};

const AddAddress = ({
  addressModal,
  addressModalClose,
  groupItem,
  item,
  setState,
  state,
  address,
  setAddress,
  addressData,
  setAddressData,
  getNoOfReg,
  setAddressModal,
  fetchData,
  onDoneClick,
}) => {
  const dispatch = useDispatch();
  const [searchAdd, setSearchAdd] = useState("");
  const [bulkUpload, setBulkUpload] = useState(false);
  const { getConfirmation } = useConfirmationDialog();

  const { id: groupId, groupName, groupType } = groupItem;

  const bulkUploadModalShow = () => setBulkUpload(true);
  const bulkUploadModalClose = () => {
    setBulkUpload(false);
    // setState(false);
  };

  const getAddress = async () => {
    try {
      dispatch(setLoader(true));
      const response = await AllowlistApiCall("GET", `/rest/allowlist-registrants/${groupId}`);
      setAddressData(response?.data);
      getNoOfReg();
      dispatch(setLoader(false));
      if (!response) {
        toast.error("Failed to load. Please try again later.");
        dispatch(setLoader(false));
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong.");
    }
  };

  useEffect(() => {
    getAddress();
  }, []);

  const addAddress = async () => {
    try {
      dispatch(setLoader(true));
      const res = await AllowlistApiCall("POST", `/rest/allowlist-registrants`, {
        allowlistRegistrants: {
          groupId: groupId,
          urlSlug: item.urlSlug,
          walletAddresses: address.map((item) => item.value),
        },
      });
      dispatch(setLoader(false));
      if (res?.status === "ERROR") {
        if (res?.statusCode === 409) {
          const dd = address.map((item) => ({
            ...item,
            label: cutAddress(item.label),
            isValid: res?.data.find((item1) => item1 === item?.value) ? true : item.isValid,
          }));
          setAddress(dd);
          return;
        }
        return toast.error(res?.message || "Something went wrong.");
      }
      setState(true);
      getNoOfReg();
      setAddress([]);
      getAddress();
      fetchData();
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const handleClear = async () => {
    try {
      const confirmed = await getConfirmation({
        title: "Are you sure you want to delete all addresses?",
      });

      if (confirmed) {
        dispatch(setLoader(true));
        await AllowlistApiCall("DELETE", `/rest/allowlist-registrants/${groupId}`);
        dispatch(setLoader(false));
        getNoOfReg();
        getAddress();
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong.");
    }
  };

  const deleteAddress = async (walletAddress) => {
    try {
      const confirmed = await getConfirmation({
        title: "Are you sure you want to this wallet ?",
      });
      if (confirmed) {
        dispatch(setLoader(true));
        const response = await AllowlistApiCall("DELETE", `/rest/allowlist-registrants/${groupId}/${walletAddress}`);
        dispatch(setLoader(false));

        if (!response) {
          toast.error("Failed to load. Please try again later.");
        } else {
          getNoOfReg();
          getAddress();
        }
        addressModalClose();
      }
    } catch (error) {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
      addressModalClose();
    }
  };

  const filterData = addressData?.filter((item) => item?.walletAddress?.search(searchAdd) >= 0);
  const isAddressExits = address.find((item) => item.isValid);

  const onChange = (val) => {
    let isInvalid = false;
    val.map((item) => {
      if (!ethers.utils.isAddress(item.value)) {
        toast.error("Invalid address.");
        isInvalid = true;
      }
    });
    if (!isInvalid) {
      const mappedVal = val.map((item) => ({
        ...item,
        label: cutAddress(item.label),
        isValid: filterData.find((item1) => item1.walletAddress === item?.value),
      }));
      setAddress(mappedVal);
    }
  };

  return (
    <>
      <Modal className="edit-modal add-address" size="lg" centered show={addressModal} onHide={addressModalClose}>
        <Modal.Header closeButton className="border-0 pb-3">
          <h4>{groupName}</h4>
        </Modal.Header>
        <Modal.Body>
          {addressData.length === 0 && (
            <p className="sub-heading mb-2">{`You have no addresses in this group yet. Add them below`}</p>
          )}

          {!state ? (
            <div className="comon-input-div mt-4 mb-5">
              <div className="remove-option-box">
                <label>Add Address(es)</label>
                <CreatableSelect
                  isClearable={false}
                  isMulti
                  onChange={(val) => onChange(val)}
                  options={[]}
                  value={address}
                  classNamePrefix={`form-select-custom`}
                  placeholder={
                    <p>
                      Type/Paste an address here or{" "}
                      <button className="bulk-upload" onClick={() => bulkUploadModalShow()}>
                        bulk upload.
                      </button>
                    </p>
                  }
                  styles={colorStyles}
                  components={{
                    Input: (props) => (
                      <components.Input
                        {...props}
                        onPaste={(e) => {
                          const dd = e.clipboardData.getData("text");
                          const isExit = address?.find((item) => item.value === dd);
                          if (isExit) {
                            toast.error("Wallet address already exit.");
                          } else {
                            onChange(
                              dd
                                .split(",")
                                .map((item) => ({ label: item, value: item }))
                                .concat(address)
                            );
                          }
                        }}
                      />
                    ),
                  }}
                />

                {isAddressExits && (
                  <div className="d-flex align-items-center gap-2">
                    <FaInfoCircle color="#ff0000" />
                    <p>Wallets marked by red already exists.</p>
                  </div>
                )}
                {address.length > 0 && (
                  <>
                    <button className="address-length" onClick={() => (isAddressExits ? null : addAddress())}>
                      Add {address.length} Address(es)
                    </button>
                    <span className="clear-address" onClick={() => setAddress([])}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="23"
                        viewBox="0 0 11 23"
                        className="me-1"
                      >
                        <text
                          id="_"
                          data-name="•"
                          transform="translate(0 18)"
                          fill="#651fc0"
                          fontSize="18"
                          fontFamily="Futura-Medium, Futura"
                          fontWeight="500"
                        >
                          <tspan x="0" y="0">
                            •
                          </tspan>
                        </text>
                      </svg>
                      Clear
                    </span>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="search-address-result">
              <div className="search-field">
                <div className="input-field">
                  <input
                    type="text"
                    placeholder="Search wallet address"
                    value={searchAdd}
                    onChange={(e) => setSearchAdd(e.target.value)}
                  />
                  <button className="remove-text-btn" onClick={() => setSearchAdd("")}>
                    <GrClose />
                  </button>
                </div>

                <div className="address-right">
                  <ul>
                    <li>{`${addressData.length} Addresses`}</li>
                    <li>
                      <button onClick={() => handleClear()}>• Clear All</button>
                    </li>
                  </ul>
                  <button className="plus-address" onClick={() => setState(false)}>
                    + Add Address(es)
                  </button>
                </div>
              </div>
              {searchAdd && (
                <div className="search-result-area">
                  <p>{searchAdd && (filterData.length ? "Search Result" : "This address is not in the list!")}</p>
                  {searchAdd && (
                    <div className="searched-result">
                      <p>{searchAdd}</p>
                      <button onClick={() => setSearchAdd("")}>
                        <img src={RemovePurple} alt="remove" />
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div className="common-search-result">
                <ul>
                  {filterData?.map((item, index) =>
                    index === 14 ? (
                      <li key={index} className="border-0 p-0">
                        + {addressData?.length - index} more
                      </li>
                    ) : (
                      index < 14 && (
                        <li key={index}>
                          <p>{cutAddress(item?.walletAddress)}</p>
                          <button
                            onClick={() => {
                              deleteAddress(item?.walletAddress);
                            }}
                          >
                            <img src={Remove} alt="remove" />
                          </button>
                        </li>
                      )
                    )
                  )}
                </ul>
              </div>
            </div>
          )}
        </Modal.Body>
        {state && (
          <Modal.Footer className="justify-content-end border-0 pb-5">
            <button
              onClick={() => {
                setState(false);
                addressModalClose();
                onDoneClick();
              }}
              className="btn next-btn m-0"
            >
              Done
              <span className="d-flex">
                <img src={ArrowRight} alt="Icon" />
              </span>
            </button>
          </Modal.Footer>
        )}
      </Modal>
      <BulkUpload
        bulkUpload={bulkUpload}
        bulkUploadModalClose={bulkUploadModalClose}
        allowlistId={item?.id}
        groupType={groupType}
        groupId={groupId}
        setState={setState}
        setAddressModal={setAddressModal}
        getAddress={getAddress}
        fetchData={fetchData}
      />
    </>
  );
};

export default AddAddress;
