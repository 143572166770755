import React, { useEffect, useState } from "react";
import { contractBalance } from "../../../web3.js/contractIngration";
import WithdrawConfirmation from "./Modal/WithdrawConfirmation";

const MintItem = ({ dataItem, handleWithdraw }) => {
  const [show, setShow] = useState(false);
  const [balance, setBalance] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const getBal = async () => {
      const res = await contractBalance("0xdD429aF938c08baaB357044729d5559FDA307f3d");
      setBalance(res);
      return res;
    };
    getBal();
  }, []);

  return (
    <>
      <li className="position-relative">
        <div className="group-content-box">
          <div className="row w-100 m-0 align-items-center">
            <div className="col-3">
              <h1 className="mb-0">{dataItem?.minted || 0}</h1>
              <p className="mb-0">Minted</p>
            </div>
            <div className="col-3">
              <h1 className="mb-0">{dataItem?.ownerReserve}</h1>
              <p className="mb-0">Owners</p>
            </div>
            <div className="col-2">
              <h1 className="mb-0">{dataItem?.floor || 0}</h1>
              <p className="mb-0">Floor(ETH)</p>
            </div>
            <div className="col-4">
              <h1 className="mb-0">{balance}</h1>
              <p className="mb-0">Contract Balance(ETH)</p>
              <button className="btn-gradient btn-gradient-after mt-2" onClick={handleShow}>
                Withdraw
              </button>
            </div>
          </div>
        </div>
      </li>

      <WithdrawConfirmation show={show} handleClose={handleClose} dataItem={dataItem} handleWithdraw={handleWithdraw} />
    </>
  );
};

export default MintItem;
