import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useMetaMask } from "metamask-react";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

import Footer from "./components/Footer";
import Loader from "./assets/Gif/8080laoder.gif";
import closeIcon from "./assets/images/toastClose.png";

import Connect from "./pages/connect/Connect";
import Manage from "./pages/manage/Manage";
import BasicConfig from "./pages/home/BasicConfig";
import { useAuth } from "./hooks/useAuth";
import NotFond from "./pages/404/NotFond";

const AllRoutes = [
  {
    path: "/",
    Component: Connect,
    isPrivate: false,
  },
  {
    path: "/launch",
    Component: BasicConfig,
    isPrivate: true,
  },
  {
    path: "/manage",
    Component: Manage,
    isPrivate: true,
  },
  {
    path: "*",
    Component: NotFond,
    isPrivate: false,
  },
];

const AuthContainer = ({ children, isPrivate }) => {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { status, account, chainId } = useMetaMask();
  const { logout } = useAuth();

  const token = localStorage.getItem("token");
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }

  useEffect(() => {
    if (status === "notConnected") {
      navigate("/");
    }
  }, [status]);

  useEffect(() => {
    if (
      account &&
      token &&
      user &&
      user.wallet_address &&
      user.wallet_address.toLowerCase() !== account.toLowerCase()
    ) {
      logout();
    }
  }, [account]);

  useEffect(() => {
    if (status !== "initializing") {
      if (isPrivate) {
        if (status !== "connected" || !token) {
          navigate("/");
        }
      }
      setIsLoading(false);
    }
  }, [status, token]);

  return isLoading ? null : <div>{children}</div>;
};

function App() {
  const loader = useSelector(({ loader }) => loader);
  return (
    <>
      <Routes>
        {AllRoutes.map(({ Component, path, isPrivate }, i) => (
          <Route
            exact={true}
            path={path}
            key={i}
            element={
              <AuthContainer isPrivate={isPrivate}>
                <Component />
              </AuthContainer>
            }
          />
        ))}
      </Routes>
      <Footer />
      <ToastContainer
        position={"top-center"}
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        closeButton={<img style={{ width: "9px", height: "9px" }} src={closeIcon} />}
      />
      <div
        style={
          !loader
            ? {
                animation: "outAnimation 1000ms ease-out",
                animationFillMode: "forwards",
              }
            : {}
        }
      >
        <div className="custom-loader">
          <img src={Loader} alt="loading..." />
        </div>
      </div>
    </>
  );
}

export default App;
