import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useFormPersist from "react-hook-form-persist";
import { toast } from "react-toastify";
import { BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { basicConfigValidation } from "../validationSchema";
import { AllowlistApiCall, ApiCall, ApiGetFile } from "../../../utils/ApiUtils";
import FormInput from "../../../components/FormInput";
import ArrowRight from "../../../assets/images/arrowRight.png";
import {
  setIsEditForgeId,
  setLoader,
  setSavedFirstStep,
  setSaveDraftData,
  setSelectedAllowlistGroups,
} from "../../../store/reducer/index";

const allFields = [
  "allowlist",
  "name",
  "symbol",
  "withdrawal",
  "urlSlug",
  "logo",
  "icon",
  "background",
  "primaryColour",
  "secondaryColour",
  "chain",
  "implementation",
  "maxSupply",
  "ownerReserve",
  "preRevealURI",
  "postRevealURI",
];

const initialWithdrawalValues = { walletAddress: "", percentageSplit: "" };

const BasicForm = ({ goNext }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saveDraftData = useSelector(({ saveDraftData }) => saveDraftData);
  const savedFirstStep = useSelector(({ savedFirstStep }) => savedFirstStep);
  const isEditForgeId = useSelector(({ isEditForgeId }) => isEditForgeId);

  const [allowListItems, setAllowListItems] = useState([]);
  const [allowlistOptions, setAllowlistOptions] = useState([]);
  const [allowlistGroups, setAllowlistGroups] = useState([]);
  const [perTotalValidation, setPerTotalValidation] = useState({ error: false, message: "" });

  const forgeId = isEditForgeId;
  const isEditMode = !!isEditForgeId;

  const local = localStorage?.getItem("basicForm");
  const localParsed = local && JSON.parse(localStorage?.getItem("basicForm"));
  const localWithdrawal = localParsed && localParsed?.withdrawal;

  const initialWithdrawal = localWithdrawal && localWithdrawal.length > 0 ? localWithdrawal : [initialWithdrawalValues];

  const methods = useForm({
    resolver: yupResolver(basicConfigValidation),
    defaultValues: {
      allowlistId: "",
      name: "",
      symbol: "",
      description: "",
      urlSlug: "",
      logo: "",
      icon: "",
      background: "",
      primaryColour: "#E64242",
      secondaryColour: "#A742E6",
      chain: "",
      implementation: "",
      maxSupply: "",
      ownerReserve: "",
      preRevealURI: "",
      postRevealURI: "",
      withdrawal: initialWithdrawal,
    },
    mode: "all",
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    trigger,
    setValue,
    getValues,
    formState: { errors, touchedFields },
    clearErrors,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "withdrawal",
  });

  useFormPersist("basicForm", {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const primary = watch("primaryColour");
  const secondary = watch("secondaryColour");
  const withdrawal = watch("withdrawal");

  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (forgeId) {
      populateEditData();
      return () => {
        dispatch(setIsEditForgeId(false));
        localStorage.removeItem("basicForm");
      };
    }
  }, [forgeId]);

  const populateEditData = async () => {
    try {
      dispatch(setLoader(true));
      const result = await ApiCall("GET", `/rest/forge/${forgeId}`);

      const editUserData = result;
      if (editUserData) {
        setValue("allowlistId", editUserData?.allowlistId);
        setValue("name", editUserData?.name);
        setValue("symbol", editUserData?.symbol);
        setValue("description", editUserData?.description);
        setValue("urlSlug", editUserData?.urlSlug);
        setValue("primaryColour", editUserData?.primaryColour);
        setValue("secondaryColour", editUserData?.secondaryColour);
        setValue("projectWebsite", editUserData?.projectWebsite);
        setValue("chain", editUserData?.chain);
        setValue("implementation", editUserData?.implementation);
        setValue("maxSupply", editUserData?.maxSupply);
        setValue("ownerReserve", editUserData?.ownerReserve);

        setValue("preRevealURI", editUserData?.preRevealURI);
        setValue("postRevealURI", editUserData?.postRevealURI);
        setValue("withdrawal", editUserData?.withdrawal);

        withdrawal &&
          withdrawal.forEach((_, index) => {
            setValue(`withdrawal.${index}.walletAddress`, editUserData?.withdrawal[index].walletAddress);
            setValue(`withdrawal.${index}.percentageSplit`, editUserData?.withdrawal[index].percentageSplit);
          });

        const dd1 = await ApiGetFile(editUserData?.projectBackgroundImage);
        const dd2 = await ApiGetFile(editUserData?.projectLogo);
        const dd3 = await ApiGetFile(editUserData?.projectIcon);

        setValue("projectBackgroundImage", dd1 ? dd1?.originalFilename : "");
        setValue("background", editUserData?.background);

        setValue("logo", dd2 ? dd2?.originalFilename : "");
        setValue("projectLogo", editUserData?.projectLogo);

        setValue("icon", dd3 ? dd3?.originalFilename : "");
        setValue("projectIcon", editUserData?.projectIcon);
      }

      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Failed to load. Please try again later.");
      console.log(error);
    }
  };

  useEffect(() => {
    if (savedFirstStep) {
      ApiCall("GET", `/rest/forge/${savedFirstStep.id}`).then(async ({ data }) => {
        const dd1 = await ApiGetFile(data?.projectBackgroundImage);
        const dd2 = await ApiGetFile(data?.projectLogo);
        const dd3 = await ApiGetFile(data?.projectIcon);

        if (dd1) setValue("background", dd1?.originalFilename);
        if (dd2) setValue("logo", dd2?.originalFilename);
        if (dd3) setValue("icon", dd3?.originalFilename);
      });
    }
  }, [savedFirstStep]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    const allVal = getValues();
    Object.keys(allVal).forEach((key) => (allVal[key] === undefined || allVal[key] === "") && delete allVal[key]);
    if (Object.keys(allVal).length > 2) {
      if (window.confirm("Are you sure you want to leave page? All unsaved progress will be lost.")) {
        navigate("/");
      }
    }
  };
  const onSubmit = async (data) => {
    try {
      if (Object.keys(touchedFields).length > 0) {
        if (isEditMode) {
          dispatch(setLoader(true));
          delete data["urlSlug"];
          delete data["discord"];
          await ApiCall("PUT", "/rest/forge/basicConfig/" + forgeId, {
            basicConfig: data,
          });
        } else {
          if (savedFirstStep || saveDraftData) {
            dispatch(setLoader(true));
            const id = savedFirstStep ? savedFirstStep?.id : saveDraftData?.id;
            await ApiCall("PUT", "/rest/forge/basicConfig/" + id, {
              ...data,
            });
            dispatch(setSavedFirstStep({ ...savedFirstStep, ...data }));
          } else {
            dispatch(setLoader(true));
            const resp = await ApiCall("POST", "/rest/forge/basicConfig", {
              ...data,
            });
            dispatch(setSavedFirstStep(resp));
          }
          dispatch(setLoader(false));
          toast.success(isEditMode ? "Updated Successfully!" : "Draft saved!");
        }
      }

      isEditMode ? navigate("/manage") : goNext();

      const element = document.getElementById("custom-navbar");
      element.scrollIntoView();
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error);
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const saveDraft = async () => {
    try {
      trigger(allFields);

      const allVal = getValues();

      const withdrawal = methods.getFieldState("withdrawal");

      withdrawal &&
        withdrawal.error &&
        withdrawal.error.forEach(async (_, index) => {
          await trigger([`withdrawal.${index}.walletAddress`, `withdrawal.${index}.percentageSplit`]);
        });

      delete allVal["description"];
      const isError = await Object.values(allVal).map((value) => {
        if (!value) return true;
        if (Array.isArray(value)) {
          if (Object.values(value).map((item) => !item)[0]) {
            return true;
          }
        }
      });

      if (isError.length > 0 && isError.some((value) => value)) {
        window.scrollTo(0, 300);
        errors?.name?.ref.focus();
        toast.error(
          <p className="text-center">
            To save draft, fill in the fields <br /> marked by red dots.
          </p>
        );
        clearErrors(allFields);
        withdrawal &&
          withdrawal.error &&
          withdrawal.error.forEach((_, index) => {
            clearErrors([`withdrawal.${index}.walletAddress`, `withdrawal.${index}.percentageSplit`]);
          });
        return;
      }

      Object.keys(allVal).forEach((key) => !allVal[key] && delete allVal[key]);

      if (saveDraftData && saveDraftData.urlSlug === allVal.urlSlug) delete allVal["urlSlug"];

      const isUpdate = saveDraftData && Object.keys(saveDraftData).length > 0;
      dispatch(setLoader(true));
      const resp = await ApiCall(
        isUpdate ? "PUT" : "POST",
        `/rest/forge/basicConfig/${isUpdate ? saveDraftData.id : ""}`,
        {
          ...allVal,
        }
      );
      dispatch(setLoader(false));
      if (resp) {
        dispatch(setLoader(false));
        dispatch(setSaveDraftData(resp));
        toast.success("Draft saved!");
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const checkURLSlug = async (value) => {
    try {
      const resp = await ApiCall("GET", `/rest/forge/slugAvailabilityCheck/${value}`);
      if (!resp) {
        toast.error("URL slug already used");
      }
    } catch (error) {
      toast.error("Something went wrong while checking the URL");
    }
  };

  const handleSelectCreateButton = (e) => {
    e.preventDefault();
    window.open("https://hyp3.0xytocin.xyz/", "_blank");
  };

  const getAllowlists = async () => {
    try {
      setAllowlistOptions([{ label: "Loading...", value: null, isDisabled: true }]);

      AllowlistApiCall("GET", `/rest/allowlist`).then((result) => {
        if (result.data && result.data.length > 0) {
          setAllowListItems(result.data);
        }
      });
    } catch (err) {}
  };

  useEffect(() => {
    getAllowlists();
  }, []);

  useEffect(() => {
    const selectedAllowListId = watch("allowlistId");
    if (!!selectedAllowListId) {
      handleAllowlistSelect(selectedAllowListId);
    }
  }, [allowListItems]);

  useEffect(() => {
    const options = [];
    allowListItems &&
      allowListItems.forEach((item) => {
        options.push({ label: item.allowListName, value: item.id, groups: item.allowlistGroup });
      });
    setAllowlistOptions(options);
  }, [allowListItems]);

  const handleAllowlistSelect = async (selectedAllowlistId) => {
    const selectedAllowlist = allowListItems.find((allowOption) => allowOption.id === selectedAllowlistId);
    if (selectedAllowlist) {
      const allowlistGroup = selectedAllowlist?.allowlistGroup;
      if (allowlistGroup.length > 0) {
        AllowlistApiCall("GET", `/rest/allowlist-registrants/count/getRegistrantsCount/${selectedAllowlistId}`).then(
          (result) => {
            const arr = [];

            allowlistGroup.forEach((item) => {
              const obj = {
                id: "",
                name: "",
                count: 0,
                price: 0,
                limitPerWallet: 0,
                maxAllocated: 0,
                listType: 0,
                isMinting: false,
                isSaleCreated: false,
              };
              obj.groupName = item.groupName;
              obj.id = item.id;

              result.data.forEach((i) => {
                if (i._id === item.id) {
                  obj.count = i.count;
                }
              });

              arr.push(obj);
            });
            dispatch(setSelectedAllowlistGroups(arr));
            setAllowlistGroups(arr);
          }
        );
      }

      // prefill data if allowlist is selected
      if (!watch("name")) setValue("name", selectedAllowlist?.projectName);
      if (!watch("description")) setValue("description", selectedAllowlist?.projectDescription);
      if (!watch("urlSlug")) setValue("urlSlug", selectedAllowlist?.urlSlug);
      if (!watch("primaryColour")) setValue("primaryColour", selectedAllowlist?.primaryColour);
      if (!watch("secondaryColour")) setValue("secondaryColour", selectedAllowlist?.secondaryColour);

      const dd1 = await ApiGetFile(selectedAllowlist?.projectBackgroundImage);
      const dd2 = await ApiGetFile(selectedAllowlist?.projectLogo);
      const dd3 = await ApiGetFile(selectedAllowlist?.projectIcon);

      if (dd1) {
        setValue("projectBackgroundImage", dd1.originalFilename);
        setValue("background", dd1?.originalFilename);
      }

      if (dd2) {
        setValue("projectLogo", dd2.originalFilename);
        setValue("logo", dd2?.originalFilename);
      }

      if (dd3) {
        setValue("projectIcon", dd3.originalFilename);
        setValue("icon", dd3?.originalFilename);
      }
      await trigger([
        "name",
        "description",
        "urlSlug",
        "primaryColour",
        "secondaryColour",
        "projectBackgroundImage",
        "projectLogo",
        "projectIcon",
      ]);
    }
  };

  const clearWithrawalError = () => {
    errors &&
      errors.withdrawal &&
      errors.withdrawal.forEach((_, index) => {
        if (_?.percentageSplit?.type === "lessThanZeroOnly") {
          clearErrors([`withdrawal.${index}.percentageSplit`]);
        }
      });
  };

  const triggerWithrawalError = () => {
    withdrawal &&
      withdrawal.forEach(async (_, index) => {
        await trigger([`withdrawal.${index}.walletAddress`, `withdrawal.${index}.percentageSplit`]);
      });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await handleSubmit(onSubmit)();
          // eslint-disable-next-line
          for (let [key, value] of Object.entries(methods.formState.errors)) {
            if (value?.type === "required") {
              toast.error(
                <p className="text-center">
                  To proceed, please fill all the fields <br /> marked by red dots.
                </p>
              );
              break;
            }
          }
        }}
      >
        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block">
          <div className="d-md-flex justify-content-between align-items-center">
            <h3 style={{ opacity: isEditMode ? 0.5 : 1 }}> Configure Allowlist </h3>
            <button className="create-mint-btn" onClick={handleSelectCreateButton}>
              Create new Allowlist
            </button>
          </div>
          <p style={{ opacity: isEditMode ? 0.5 : 1 }}>
            {" "}
            Link an allowlist below. A lot of the parameters on this page will be auto-filled from the allowlist you
            select/create for this mint.{" "}
          </p>
          <div className="row">
            <div className="col-lg-12">
              <FormInput
                name={`allowlistId`}
                type="select"
                placeholder={"Pick Allowlist Or Create A New One"}
                onMenuOpen={getAllowlists}
                options={allowlistOptions}
                createButton={allowlistOptions.length === 0}
                handleSelectCreateButton={handleSelectCreateButton}
                onChange={(e) => handleAllowlistSelect(e.target.value)}
                disabled={isEditMode}
              />
            </div>
            <div className="col-lg-12">
              <div className="show-allow-lists" style={{ opacity: isEditMode ? 0.5 : 1 }}>
                {allowlistGroups.length === 0 && <span>Select an allowlist above to view eligible groups</span>}
                {allowlistGroups.length > 0 &&
                  allowlistGroups.map((item, index) => (
                    <span key={index} className="group-name">{`${item.groupName}(${item.count})`}</span>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block">
          <h3 style={{ opacity: isEditMode ? 0.5 : 1 }}> Basic Info </h3>
          <p style={{ opacity: isEditMode ? 0.5 : 1 }}> Let’s name your project! </p>
          <div className="row">
            <div className="col-lg-6">
              <FormInput name="name" type="text" placeholder="Drop Name" disabled={isEditMode} />
            </div>
            <div className="col-lg-6">
              <FormInput name="symbol" type="text" placeholder="Drop Symbol" disabled={isEditMode} />
            </div>
            <div className="col-lg-12">
              <FormInput
                rows={4}
                type="textarea"
                name="description"
                placeholder="Description (Optional)"
                disabled={isEditMode}
              />
            </div>
            <div className="col-lg-12"></div>
          </div>
        </div>
        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block">
          <h3 style={{ opacity: isEditMode ? 0.5 : 1 }}> Minting dApp URL </h3>
          <p style={{ maxWidth: "915px", opacity: isEditMode ? 0.5 : 1 }}>
            The slug allows users to find your Minting dApp! The dApp will be launched at <br />
            <NavLink to="/" className="links-nm text-lowercase">
              <b style={{ fontSize: 18 }}>https://&lt;url slug&gt;.8080.tools</b>
            </NavLink>
          </p>
          <div className="row">
            <div className="col-lg-12">
              <div
                className={`form-group ${errors.urlSlug?.message ? "form-error" : ""} ${
                  errors.urlSlug?.type === "required" ? "dot " : ""
                }`}
              >
                <Controller
                  name="urlSlug"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="URL Slug"
                      disabled={isEditMode}
                      {...field}
                      onBlur={() => field.value && checkURLSlug(field.value)}
                    />
                  )}
                />
                {errors.urlSlug && errors.urlSlug?.type !== "required" && (
                  <div className="error-text mt-1">
                    <span className="info">i</span>
                    <span>{errors.urlSlug.message}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block">
          <h3> Look & Feel </h3>
          <p>Now configure the look & feel of your mint site.</p>
          <div className="row">
            <div className="col-lg-6">
              <FormInput name="projectLogo" type="file" placeholder="Project Logo (300 X 200)" fileUploadName="logo" />
            </div>
            <div className="col-lg-6">
              <FormInput
                name="projectIcon"
                type="file"
                placeholder="Project Icon (500 X 500) (Optional)"
                fileUploadName="icon"
              />
            </div>
            <div className="col-lg-12">
              <FormInput
                name="projectBackgroundImage"
                type="file"
                placeholder="Background image (1920 X 1080)"
                fileUploadName="background"
              />
            </div>
            <div className="col-lg-6">
              <FormInput type="color" className="comon-color-div1" name="primaryColour" label="Primary Colour " />
            </div>
            <div className="col-lg-6">
              <FormInput type="color" className="comon-color-div1" name="secondaryColour" label="Secondary Colour" />
            </div>
            {/* <div className="col-lg-6">
              <div className={`form-group comon-color-div1 ${errors.primaryColour?.message ? "form-error" : ""}`}>
                <label> Primary Colour </label>
                <div className="color-dvm d-flex align-items-center">
                  <span className="color-preview" style={{ backgroundColor: primary, border: "1.5px solid #631DC3" }} />
                  <input type="text" className="form-control " {...register("primaryColour")} />
                </div>
                {errors.primaryColour && errors.primaryColour?.type !== "required" && (
                  <div className="error-text mt-1">
                    <span className="info">i</span>
                    <span>{errors.primaryColour.message}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className={`form-group comon-color-div1 ${errors.secondaryColour?.message ? "form-error" : ""}`}>
                <label> Secondary Colour </label>
                <div className="color-dvm d-flex align-items-center">
                  <span
                    className="color-preview"
                    style={{ backgroundColor: secondary, border: "1.5px solid #631DC3" }}
                  />
                  <input type="text" className="form-control" {...register("secondaryColour")} />
                </div>
                {errors.secondaryColour && errors.secondaryColour?.type !== "required" && (
                  <div className="error-text mt-1">
                    <span className="info">i</span>
                    <span>{errors.secondaryColour.message}</span>
                  </div>
                )}
              </div>
            </div> */}
          </div>
        </div>
        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block">
          <h3 style={{ opacity: isEditMode ? 0.5 : 1 }}> Contract Specs </h3>
          <p style={{ opacity: isEditMode ? 0.5 : 1 }}>Key configuration of the minting contract.</p>
          <div className="row">
            <div className="col-lg-12">
              <FormInput
                name={`chain`}
                type="select"
                disabled={isEditMode}
                placeholder={"Pick Chain"}
                options={[{ label: "Ethereum", value: "Ethereum" }]}
              />
            </div>
            <div className="col-lg-12">
              <FormInput
                name={`implementation`}
                type="select"
                disabled={isEditMode}
                placeholder={"Implementation"}
                options={[
                  { label: "ERC721A", value: "ERC721A" },
                  { label: "ERC721Enumerable", value: "ERC721Enumerable" },
                ]}
              />
            </div>
          </div>
        </div>

        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block">
          <h3> Mint Configuration </h3>
          <p> Specify total supply, owner reserves and the mint limit per wallet. </p>
          <div className="comon-opcity-div mt-0">
            <div className="row">
              <div className="col-lg-6">
                <FormInput
                  name="maxSupply"
                  type="text"
                  placeholder=""
                  className="mb-0"
                  label="Total Supply"
                  disabled={isEditMode}
                />
              </div>
              <div className="col-lg-6">
                <FormInput
                  name="ownerReserve"
                  type="text"
                  placeholder=""
                  className="mb-0"
                  label="Owner Reserves"
                  disabled={isEditMode}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <FormInput
                  name="preRevealURI"
                  type="text"
                  disabled={isEditMode}
                  placeholder=""
                  className="mb-0"
                  label="Metadata Base URI [Pre-Reveal]"
                  subLabel="Leave blank for mints with instant reveal."
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <FormInput
                  name="postRevealURI"
                  type="text"
                  placeholder=""
                  className="mb-0"
                  label="Metadata Base URI [Post-Reveal]"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block">
          <h3 style={{ opacity: isEditMode ? 0.5 : 1 }}> Withdrawal </h3>
          <p style={{ opacity: isEditMode ? 0.5 : 1 }}>
            Specify the addresses and the percentage from the mint they make at withdrawal.
          </p>
          <div className="comon-opcity-div mt-0">
            {fields.map((_, index) => (
              <>
                <div className="row" key={`withdrawal.${index}`}>
                  <div className="col-lg-7">
                    <FormInput
                      disabled={isEditMode}
                      name={`withdrawal.${index}.walletAddress`}
                      type="text"
                      className="mb-0"
                      label="Wallet Address"
                    />
                  </div>

                  <div className="col-lg-4">
                    <FormInput
                      disabled={isEditMode}
                      name={`withdrawal.${index}.percentageSplit`}
                      type="text"
                      className="mb-2 w-100"
                      inputClassName="w-100"
                      label="Percentage Split"
                      step={0.1}
                    />
                  </div>
                  <div className="col-lg-1 pos-rel">
                    <div className="form-group pos-abs bottom-0">
                      {index ? (
                        <button
                          type="button"
                          className="button btn remove remove-btn"
                          onClick={() => {
                            remove(index);
                            triggerWithrawalError();
                          }}
                          disabled={isEditMode}
                        >
                          X
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          {perTotalValidation.error && <span className="red-color">{perTotalValidation.message}</span>}
          <button
            type="button"
            className="btn btn-add-wallet"
            onClick={() => {
              clearWithrawalError();
              append({ ...initialWithdrawalValues });
            }}
            disabled={isEditMode}
          >
            <BiPlus /> Add Wallet
          </button>
        </div>

        <div className="inside-div-cm col-lg-10 w-90 mx-auto d-block mb-4">
          <div className="d-sm-flex align-items-center justify-content-end">
            {isEditMode ? (
              <div className="right-pre-div d-flex align-items-center justify-content-between">
                <button type="submit" className="btn next-btn" disabled={Object.keys(touchedFields).length === 0}>
                  Done
                  <span className="d-flex">
                    <img src={ArrowRight} alt="Icon" />
                  </span>
                </button>
              </div>
            ) : (
              <div className="right-pre-div d-flex align-items-center justify-content-between">
                <button type="button" onClick={saveDraft} className="btn pre-btn">
                  Save Draft
                </button>
                <button type="submit" className="btn next-btn">
                  Next
                  <span className="d-flex">
                    <img src={ArrowRight} alt="Icon" />
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default BasicForm;
