import React, { useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { BiUpload } from "react-icons/bi";
import { ApiFileUpload } from "../utils/ApiUtils";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { setLoader } from "../store/reducer";
import { useDispatch } from "react-redux";
import Dropdown from "../assets/images/down-arrow.svg";
import { toast } from "react-toastify";
import ColorButton from "./ColorButton";

function FormInput({
  name,
  defaultValue,
  selected,
  onChange,
  placeholder,
  type = "text",
  label,
  disabled,
  options = [],
  onMenuOpen,
  className = "",
  value,
  suffix,
  prefix,
  mask,
  fileUploadName,
  inputClassName,
  isMulti,
  selectedValue,
  subLabel,
  createButton = false,
  handleSelectCreateButton,
  onBlur,
  rows,
  onKeyPress,
  ...rest
}) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const [isShowPasssword, setIsShowPassword] = useState(false);
  const uploadIcon = useRef(null);

  const arrFieldName = name.split(".");
  const isArrayField = arrFieldName.length >= 2;
  const error = isArrayField
    ? methods.formState.errors?.[arrFieldName[0]]?.[parseInt(arrFieldName[1])]?.[arrFieldName[2]]
    : methods.formState.errors[name];

  const fieldRegister = methods.register(name, { onChange: onChange ? onChange : () => {} });

  return (
    <>
      <div className="d-md-flex justify-content-between align-items-center">
        {type !== "checkbox" && label && <Form.Label style={{ opacity: disabled ? 0.5 : 1 }}>{label} </Form.Label>}
        {type !== "checkbox" && subLabel && (
          <Form.Label style={{ fontSize: 15, opacity: disabled ? 0.5 : 1 }}>{subLabel} </Form.Label>
        )}
      </div>

      <Form.Group
        className={`form-group ${error ? "form-error" : ""} ${error?.type === "required" ? "dot " : ""}` + className}
      >
        {(() => {
          switch (type) {
            case "password":
              return (
                <InputGroup className="input_group">
                  <Form.Control
                    type={isShowPasssword ? "text" : "password"}
                    placeholder={placeholder}
                    disabled={disabled}
                    {...fieldRegister}
                    autoComplete="off"
                  />
                  <InputGroup.Text onClick={() => setIsShowPassword(!isShowPasssword)} id="create-password">
                    <BiUpload />
                  </InputGroup.Text>
                </InputGroup>
              );

            case "select":
              return (
                <Controller
                  control={methods.control}
                  name={name}
                  render={({ field }) => (
                    <Select
                      {...field}
                      classNamePrefix="select form-select-custom"
                      options={options}
                      isMulti={isMulti}
                      onChange={(data) => {
                        if (isMulti) field.onChange(data);
                        else field.onChange(data?.value ? String(data?.value) : "");
                      }}
                      placeholder={placeholder}
                      value={isMulti ? field.value : options.find((item) => item.value === field.value)}
                      isClearable={false}
                      hideSelectedOptions
                      defaultValue={selectedValue}
                      onMenuOpen={onMenuOpen}
                      isDisabled={disabled}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: (props) => (
                          <components.DropdownIndicator {...props}>
                            <img src={Dropdown} alt="icon" className="icon" style={{ marginRight: "15px" }} />
                          </components.DropdownIndicator>
                        ),
                        NoOptionsMessage: (props) => (
                          <components.NoOptionsMessage {...props}>
                            {createButton ? (
                              <p style={{ cursor: "text" }}>
                                You don't have an mint yet.
                                <span
                                  style={{ color: "#CD46F4", cursor: "pointer" }}
                                  onClick={handleSelectCreateButton}
                                >
                                  {" "}
                                  Create now
                                </span>
                              </p>
                            ) : (
                              <span>No Options</span>
                            )}
                          </components.NoOptionsMessage>
                        ),
                      }}
                    />
                  )}
                />
              );

            case "checkbox":
              return (
                <Form.Check
                  type={type}
                  placeholder={placeholder}
                  label={label}
                  disabled={disabled}
                  {...fieldRegister}
                />
              );

            case "file":
              return (
                <div className="box" style={{ height: 52 }}>
                  <Controller
                    name={name}
                    control={methods.control}
                    render={({ field }) => {
                      const fileName = field.value?.name || methods.getValues(fileUploadName) || placeholder;
                      return (
                        <>
                          <section
                            className="outputname"
                            style={{ color: methods.getValues(fileUploadName) ? "#5A5A5A" : "" }}
                          >
                            {fileName}
                          </section>
                          <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            className="inputfile inputfile-1"
                            ref={uploadIcon}
                            title={fileName}
                            onChange={async (e) => {
                              if (e.target.files.length === 0) return;
                              if (fileUploadName) {
                                try {
                                  let oldValue = field.value;
                                  field.onChange(e.target.files[0]);
                                  const isVaild = await methods.trigger(name);
                                  if (isVaild) {
                                    dispatch(setLoader(true));
                                    const responseData = await ApiFileUpload(
                                      e.target.files[0],
                                      typeof field.value === "string" ? field.value : ""
                                    );
                                    field.onChange(responseData.id);
                                    methods.setValue(fileUploadName, responseData.originalFilename, {
                                      shouldTouch: true,
                                    });
                                    dispatch(setLoader(false));
                                  } else {
                                    // if (oldValue) field.onChange(oldValue);
                                  }
                                } catch (error) {
                                  console.log(error);
                                  dispatch(setLoader(false));
                                  toast.error("Something went wrong while file upload.");
                                }
                              } else {
                                field.onChange(e.target.files[0]);
                              }
                            }}
                          />
                        </>
                      );
                    }}
                  />
                  <BiUpload onClick={() => uploadIcon.current.click()} style={{ cursor: "pointer", zIndex: 99 }} />
                </div>
              );

            case "creatableSelect":
              return (
                <Controller
                  name={name}
                  control={methods.control}
                  render={({ field }) => (
                    <CreatableSelect
                      isClearable
                      isMulti
                      placeholder=""
                      classNamePrefix="form-select-custom"
                      options={[]}
                      components={{
                        IndicatorSeparator: null,
                        DropdownIndicator: null,
                      }}
                      {...field}
                    />
                  )}
                />
              );

            case "groupCheckbox":
              return (
                <div>
                  {options.map((item) => (
                    <Form.Check
                      inline
                      type="radio"
                      key={item.value}
                      label={item.label}
                      value={item.value}
                      {...fieldRegister}
                    />
                  ))}
                </div>
              );

            case "textarea":
              return (
                <textarea
                  onKeyPress={onKeyPress}
                  placeholder={placeholder}
                  disabled={disabled}
                  {...rest}
                  {...fieldRegister}
                  className={`form-control ${inputClassName ? inputClassName : ""} ${type === "date" && "select-date"}`}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => {
                    e.target.placeholder = placeholder;
                    fieldRegister.onBlur(e);
                  }}
                />
              );

            case "color":
              return <ColorButton name={name} />;

            default:
              return (
                <input
                  type={type}
                  onKeyPress={onKeyPress}
                  placeholder={placeholder}
                  disabled={disabled}
                  rows={rows}
                  {...rest}
                  {...fieldRegister}
                  className={`form-control ${inputClassName ? inputClassName : ""} ${type === "date" && "select-date"}`}
                />
              );
          }
        })()}

        {error && error?.type !== "required" && (
          <div className="error-text mt-1">
            <span className="info">i</span>
            <span>{error?.message}</span>
          </div>
        )}
      </Form.Group>
    </>
  );
}

export default FormInput;
