import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import LaunchedItem from "./LaunchedItem";
import PauseConfirmation from "./Modal/PauseConfirmation";
import MintItem from "./MintItem";
import RevealArtModal from "./Modal/RevealArtModal";
import { AllowlistApiCall, ApiCall } from "../../../utils/ApiUtils";
import { setCurrentFormStep, setIsEditForgeId } from "../../../store/reducer";
import { ReactComponent as RedirectIcon } from "../../../assets/images/redirect-icon.svg";

const Launched = ({
  dataItem,
  handlePause,
  handleResume,
  fetchData,
  handleRevealArt,
  handleWithdraw,
  isMintPaused,
  isArtReveled,
  isSearchFetch,
  isFound,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isNextFetch = useSelector(({ isNextFetch }) => isNextFetch);

  const [show, setShow] = useState(false);
  const [revealArtModal, setRevealArtModal] = useState(false);
  const [noOfReg, setNoOfReg] = useState([]);
  const [isFetchedGetNoReg, setIsFetchedGetNoReg] = useState(false);
  const [isFetchedForgeDetail, setIsFetchedForgeDetail] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRevealArtClose = () => setRevealArtModal(false);
  const handleRevealArtShow = () => setRevealArtModal(true);

  const getNoOfReg = () => {
    AllowlistApiCall("GET", `/rest/allowlist/${dataItem.allowlistId}/getRegistrantsCount`)
      .then((result) => {
        setNoOfReg(result.data);
        setIsFetchedGetNoReg(true);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to get data.");
      });
  };

  const getForgeDetail = async () => {
    try {
      const result = await ApiCall("GET", `/rest/forge/${dataItem.id}`);
      setIsFetchedForgeDetail(true);
    } catch (err) {
      console.log(err);
      toast.error("Failed to get data.");
    }
  };

  useEffect(() => {
    if (isSearchFetch || isFound) {
      getForgeDetail();
      getNoOfReg();
    }
  }, [isSearchFetch, isFound]);

  useEffect(() => {
    if (isNextFetch[dataItem.id]) {
      if (!isFetchedGetNoReg) {
        getNoOfReg();
      }
      if (!isFetchedForgeDetail) {
        getForgeDetail();
      }
    }
  }, [isNextFetch]);

  const editProjectDetail = (id) => {
    dispatch(setIsEditForgeId(id));
    dispatch(setCurrentFormStep(1));
    navigate(`/launch`);
  };

  return (
    <div className="mint-content-wrap">
      <div className="title-wrap mb-4">
        <div>
          <div className="d-flex align-items-center gap-3">
            <h1 className="mb-0">{dataItem.name}</h1>
            <a href={`${window.location.protocol}//${dataItem?.urlSlug}.forge-staging.online`} target="_blank">
              <RedirectIcon height={26} width={26} />
            </a>
          </div>
          <p className="launched-time"> {`Launched on ${dataItem.launchedDate || "22 Dec 2022"}`}</p>
          <div className="d-flex mt-md-3 mt-1 gap-2">
            <button className="btn-gradient btn-gradient-after" onClick={() => editProjectDetail(dataItem?.id)}>
              Edit
            </button>
            {!isArtReveled && (
              <button
                className="btn-gradient btn-gradient-after"
                disabled={!dataItem?.isActive}
                onClick={handleRevealArtShow}
              >
                Reveal Art
              </button>
            )}
          </div>
        </div>

        <ul>
          {!isMintPaused ? (
            <li>
              <button className="btn-gradient btn-gradient-after" onClick={handleShow}>
                Pause
              </button>
            </li>
          ) : (
            <li>
              <button
                className="btn-gradient btn-gradient-after"
                onClick={() => {
                  handleResume(dataItem?.id);
                }}
              >
                Resume
              </button>
            </li>
          )}
        </ul>
      </div>
      <div className="launched-mint-content">
        <ul>
          <MintItem dataItem={dataItem} handleWithdraw={handleWithdraw} />
        </ul>
      </div>

      <div className="launched-mint-content">
        <h2 className="mb-2"> Minting Groups </h2>
        <p>Use toggle to launch mint for a group.</p>
        <ul>
          {dataItem?.allowListGroup?.map((item, index) => (
            <LaunchedItem
              key={index}
              fetchData={fetchData}
              groupItem={item}
              dataItem={dataItem}
              index={index}
              getNoOfReg={getNoOfReg}
              groupName={noOfReg.find((item1) => item1._id === item.id)?.groupName}
              noOfRegCount={noOfReg.find((item1) => item1._id === item.id)?.count}
              getForgeDetail={getForgeDetail}
            />
          ))}
        </ul>
      </div>
      <PauseConfirmation show={show} handleClose={handleClose} dataItem={dataItem} handlePause={handlePause} />
      <RevealArtModal
        show={revealArtModal}
        handleClose={handleRevealArtClose}
        dataItem={dataItem}
        handleRevealArt={handleRevealArt}
      />
    </div>
  );
};

export default Launched;
