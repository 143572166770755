import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ArrowRight from "../../../../assets/images/arrowRight.png";
import FormInput from "../../../../components/FormInput";
import { setLoader } from "../../../../store/reducer";
import { ApiCall } from "../../../../utils/ApiUtils";
import { editMintPriceSchema } from "../../../home/validationSchema";
import MintConfirmationModal from "./MintConfirmationModal";

const EditMintPrice = ({
  editModal,
  editModalClose,
  groupName,
  isInitalized,
  confirmMintModal,
  confirmMintModalShow,
  confirmMintModalClose,
  handleConfirmMint,
  forgeId,
  groupItem,
  getForgeDetail,
}) => {
  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(editMintPriceSchema),
    defaultValues: {},
    mode: "all",
  });

  const {
    handleSubmit,
    formState: {},
  } = methods;

  const onSubmit = async (formData) => {
    try {
      dispatch(setLoader(true));
      if (isInitalized) {
        confirmMintModalShow();
      } else {
        const res = await ApiCall("PUT", "/rest/forge/pricing/" + forgeId, {
          groupId: groupItem.id,
          allowListGroup: [{ ...groupItem, ...formData }],
        });
      }
      getForgeDetail();
      editModalClose();
      dispatch(setLoader(false));
      toast.success(editModal ? "Changes saved!" : "New group added!");
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error);
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const getRequiredError = (err) => {
    for (let [key, value] of Object.entries(Array.isArray(err) ? err[0] || {} : {})) {
      dispatch(setLoader(false));
      if (value?.type === "required") {
        toast.error("Fields marked by a red dot are required.");
        break;
      } else {
        if (Array.isArray(value)) {
          getRequiredError(value);
        }
      }
    }
  };

  const handleFormSubmit = async () => {
    await handleSubmit(onSubmit)();
    getRequiredError(methods.formState.errors);
  };

  return (
    <>
      <Modal className="edit-modal" size="lg" centered show={editModal} onHide={editModalClose}>
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
            }}
          >
            <Modal.Header closeButton className="border-0">
              <h2 className="mb-0">
                <b>{groupName}</b>
              </h2>
            </Modal.Header>
            <Modal.Body>
              <p className="futura-light">Configure the pricing for this list.</p>
              <div className="inside-div-cm show-main-register-mint mb-0">
                <div className="comon-input-div mt-2">
                  <label>Price</label>
                  <div className="inpy-div d-flex mt-2">
                    <FormInput name={`price`} type="text" className="mb-0 w-100" inputClassName="w-100 " />
                    <div className="slp">
                      <span className="form-select">ETH</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <FormInput name={`limitPerWallet`} type="text" label={"Limit Per Wallet"} />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <FormInput name={`maxAllocated`} type="text" label={"Max Allocated Supply"} />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-end border-0">
              <button type="submit" className="btn next-btn m-0">
                Done
                <span className="d-flex">
                  <img src={ArrowRight} alt="Icon" />
                </span>
              </button>
            </Modal.Footer>
          </form>
        </FormProvider>
      </Modal>

      <MintConfirmationModal
        confirmMintModal={confirmMintModal}
        confirmMintModalClose={confirmMintModalClose}
        handleConfirmMint={handleConfirmMint}
        isUpdate={isInitalized}
      />
    </>
  );
};

export default EditMintPrice;
