import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { setLoader } from "../../../store/reducer";
import { ApiCall } from "../../../utils/ApiUtils";
import EditMintPrice from "./Modal/EditMintPrice";
import AddAddress from "./Modal/AddAddress";
import { setSaleConditions } from "../../../web3.js/contractIngration";
import MintConfirmationModal from "./Modal/MintConfirmationModal";

const LaunchedItem = (props) => {
  const { fetchData, groupItem, dataItem, noOfRegCount, groupName, getNoOfReg, index, getForgeDetail } = props;

  const [editModal, setEditModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [state, setState] = useState(false);
  const [address, setAddress] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [confirmMintModal, setConfirmMintModal] = useState(false);

  const dispatch = useDispatch();

  const editModalClose = () => setEditModal(false);
  const editModalShow = () => setEditModal(true);

  const confirmMintModalClose = () => setConfirmMintModal(false);
  const confirmMintModalShow = () => setConfirmMintModal(true);

  const addressModalShow = (tab) => {
    if (addressData.length > 0) {
      setState(true);
    }
    setAddressModal(true);
  };

  const addressModalClose = () => {
    setAddressModal(false);
    setState(false);
    setAddress([]);
  };

  const handleConfirmMint = async () => {
    try {
      dispatch(setLoader(true));
      // show confirm minting model if needed before calling setSaleConditions function
      const res = await setSaleConditions([
        {
          buyLimitPerWallet: 1,
          remainingTokens: 1000,
          mintPrice: ethers.utils.parseEther("0"),
          listType: 1,
          currency: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        },
      ]);
      fetchData();
      dispatch(setLoader(false));
      toast.success(groupItem.isMinting ? groupName + " is Minting." : groupName + " is not minting.");
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  const handleMinting = async () => {
    try {
      dispatch(setLoader(true));
      if (dataItem.mintingInitialize) {
        await ApiCall("PUT", `/rest/forge/toggleMinting/${groupItem.id}/${!dataItem.isMinting}`);
        toast.success(groupItem.isMinting ? groupName + " is Minting." : groupName + " is not minting.");
        fetchData();
      } else {
        if (groupItem.price < 1 || groupItem.limitPerWallet < 1 || groupItem.maxAllocated < 1) {
          editModalShow();
        } else {
          confirmMintModalShow();
        }
      }
      dispatch(setLoader(false));
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.message || "Something went wrong.");
    }
  };

  return (
    <li className="position-relative" style={{ zIndex: `${99 - index}` }}>
      <div className="group-content" style={{ marginBottom: "1.3rem" }}>
        <div className="left-content">
          <div className="d-flex align-items-center gap-3">
            <h5 className="mb-0">{groupName}</h5>
          </div>

          <div className="d-flex mt-md-3 mt-1 gap-2">
            <button className="btn-gradient btn-gradient-after" onClick={editModalShow}>
              Edit
            </button>
            <button
              className="btn-gradient btn-gradient-after"
              onClick={() => {
                addressModalShow();
              }}
            >
              Manage Addresses
            </button>
          </div>
        </div>
        <div className={`d-flex align-items-end ${groupItem?.isMinting ? "show-main-up-mint" : ""}`}>
          <button
            onClick={handleMinting}
            // disabled={!dataItem?.isActive}
            className="btn tog-btn p-0 border-0"
          >
            <div className="d-flex align-items-center active-in-toggle-btn">
              <span className="pb fs-24 futura-light"> Minting </span>
              <div className="toggle-bn"></div>
              <span className="pr fs-24 hide-z-index futura-light"> Not Minting </span>
            </div>
          </button>
        </div>
      </div>
      <div className="group-content-box">
        <div className="row w-100 m-0 align-items-center">
          <div className="col-3">
            <h1 className="mb-0">{noOfRegCount || 0}</h1>
            <p className="mb-0">Registrants</p>
          </div>
          <div className="col-3">
            <h1 className="mb-0">{`${groupItem?.percentageMinted || 0}%`}</h1>
            <p className="mb-0">Percentage Minted</p>
          </div>
          <div className="col-3">
            <h1 className="mb-0">{groupItem?.price || 0}</h1>
            <p className="mb-0">Mint Price(ETH)</p>
          </div>
          <div className="col-3">
            <h1 className="mb-0">{groupItem?.limitPerWallet}</h1>
            <p className="mb-0">Limit Per Wallet</p>
          </div>
        </div>
      </div>
      <AddAddress
        addressModal={addressModal}
        addressModalClose={addressModalClose}
        groupItem={groupItem}
        item={dataItem}
        setState={setState}
        state={state}
        address={address}
        setAddress={setAddress}
        addressData={addressData}
        setAddressData={setAddressData}
        getNoOfReg={getNoOfReg}
        setAddressModal={setAddressModal}
        fetchData={fetchData}
        onDoneClick={() => toast.success("List updated!")}
      />
      <EditMintPrice
        forgeId={dataItem.id}
        editModal={editModal}
        editModalClose={editModalClose}
        groupName={groupName}
        groupItem={groupItem}
        isInitalized={dataItem.mintingInitialize}
        confirmMintModal={confirmMintModal}
        confirmMintModalShow={confirmMintModalShow}
        confirmMintModalClose={confirmMintModalClose}
        handleConfirmMint={handleConfirmMint}
        getForgeDetail={getForgeDetail}
      />
      <MintConfirmationModal
        confirmMintModal={confirmMintModal}
        confirmMintModalClose={confirmMintModalClose}
        handleConfirmMint={handleConfirmMint}
      />
    </li>
  );
};

export default LaunchedItem;
