import * as yup from "yup";

const digitsWithDecimalOnly = (value) => /^[+-]?\d+(\.\d+)?$/.test(value);
const digitsOnly = (value) => /^\d+$/.test(Number(value));
const greaterThanZero = (value) => /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/.test(value);
const regColorCheck = /^#([0-9a-f]{3}){1,2}$/i;

yup.addMethod(yup.mixed, "imageDimensionCheck", function (message, requiredWidth, requiredHeight, isOptional) {
  return this.test("image-width-height-check", message, async function (value) {
    const { path, createError } = this;
    if (typeof value === "string") {
      return true;
    }
    if (!value) {
      if (isOptional) return true;
      else return;
    }
    try {
      if (value.type !== "image/png" && value.type !== "image/jpeg") {
        return createError({
          path,
          message: `File must be  png or jpeg`,
        });
      }
      if (value.size >= 1024 * 1024) {
        return createError({
          path,
          message: `File must be less than 1MB`,
        });
      }
      const imgDimensions = { width: null, height: null };
      await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = function () {
          const img = new Image();
          img.src = reader.result;
          img.onload = function () {
            imgDimensions.width = img.width;
            imgDimensions.height = img.height;
            resolve(imgDimensions);
          };
        };
      });
      if (requiredWidth < imgDimensions.width) {
        return createError({
          path,
          message: `The file width/height must be less than or equal to ${requiredWidth}/${requiredHeight} px!`,
        });
      }
      if (requiredHeight < imgDimensions.height) {
        return createError({
          path,
          message: `The file width/height must be less than or equal to ${requiredWidth}/${requiredHeight} px!`,
        });
      }
      return true;
    } catch (error) {
      return createError({
        path,
        message: `Error in file`,
      });
    }
  });
});

yup.addMethod(yup.array, "lessThanZeroOnly", function (message, path) {
  return this.test("lessThanZeroOnly", message, function (list) {
    let sum = 0;
    list.map((item) => (sum += parseFloat(item.percentageSplit)));
    if (sum === 100) return true;

    return this.createError({ path: `${this.path}[${list.length - 1}].${path}`, message });
  });
});

export const basicConfigValidation = yup
  .object({
    allowlistId: yup.string().required(),
    name: yup
      .string()
      .required("Project name is required")
      .min(2, "Project name must be at least 2 characters")
      .max(32, "Project name can be at most 32 characters long"),
    symbol: yup
      .string()
      .required("Project symbol is required")
      .min(2, "Project symbol must be at least 2 characters")
      .max(32, "Project symbol can be at most 32 characters long"),
    description: yup.string().nullable().max(500),
    urlSlug: yup
      .string()
      .required("URL slug is required")
      .matches(/^[0-9a-z-]+$/, "This field cannot contain white space, capital case and special character"),

    projectLogo: yup.mixed().required("A file is required").imageDimensionCheck("test", 300, 200),
    logo: yup.string(),
    projectIcon: yup.mixed().notRequired().imageDimensionCheck("test", 500, 500, true),
    icon: yup.string(),
    projectBackgroundImage: yup.mixed().required("A file is required").imageDimensionCheck("test", 1920, 1080),
    background: yup.string(),
    primaryColour: yup.mixed().test("color", "Invalid color", (value) => regColorCheck.test(value)),
    secondaryColour: yup.mixed().test("color", "Invalid color", (value) => regColorCheck.test(value)),
    chain: yup.string().required(),
    implementation: yup.string().required(),
    maxSupply: yup.string().required().nullable().test("Digits only", "The field should have digits only", digitsOnly),
    ownerReserve: yup
      .string()
      .required()
      .test("min0", "Owner Reserve should be more than 0", (value) => value > 0)
      .test("minMaxTotalSupply", "Owner Reserve should be less then Total Supply", (value, context) => {
        return parseInt(context.parent.maxSupply) > parseInt(value);
      }),
    preRevealURI: yup.string().url("Invalid link").notRequired().nullable(),
    postRevealURI: yup.string().url("Invalid link").required(),
    withdrawal: yup
      .array()
      .of(
        yup.object().shape({
          walletAddress: yup
            .string()
            .required("Wallet Address is required")
            .matches(/^0x[a-fA-F0-9]{40}$/, "ETH wallet address is invalid"),
          percentageSplit: yup.string().required("Percentage Split is required"),
        })
      )
      .lessThanZeroOnly("Wallet address should be 100 only", "percentageSplit"),
  })
  .required();

export const groupSchema = yup.object().shape({
  name: yup.string().notRequired().nullable(),
  price: yup
    .string()
    .notRequired()
    .nullable()
    .test("Digits only", "Only numbers allowed.", (value) => (value ? digitsOnly(value) : true)),
  maxAllocated: yup
    .string()
    .notRequired()
    .nullable()
    .test("Digits only", "Only numbers allowed.", (value) => (value ? digitsOnly(value) : true)),
  limitPerWallet: yup
    .string()
    .notRequired()
    .nullable()
    .test("Digits only", "Only numbers allowed.", (value) => (value ? digitsOnly(value) : true))
    .test("minMaxPerWallet", "Limit of Wallet should be less then Max Allocated Supply", (value, context) => {
      if (value) return parseFloat(context.parent.maxAllocated) >= parseFloat(value);
      return true;
    }),
  listType: yup.string().notRequired().nullable(),
  isMinting: yup.boolean(),
  isSaleCreated: yup.boolean(),
});

export const secondStepValidation = yup
  .object({
    groupList: yup.array().of(groupSchema),
  })
  .required();

export const editMintPriceSchema = yup.object().shape({
  price: yup.string().required().test("Digits only", "Only greater than 0 numbers allowed.", greaterThanZero),
  limitPerWallet: yup.string().required().test("Digits only", "Only greater than 0 numbers allowed.", greaterThanZero),
  maxAllocated: yup.string().required().test("Digits only", "Only greater than 0 numbers allowed.", greaterThanZero),
});
