import React, { createRef, useEffect, useState } from "react";
import { ImArrowRight2 } from "react-icons/im";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header";
import Launched from "./components/Launched";
import Darft from "./components/Draft";
import ArrowRight from "../../assets/images/arrowRight.png";
import { ApiCall } from "../../utils/ApiUtils";
import {
  setCurrentFormStep,
  setIsNextFetch,
  setLoader,
  setSavedFirstStep,
  setSaveDraftData,
} from "../../store/reducer";

import { initializeContract, isRevealed, pauseMint, togglePause, withdraw } from "../../web3.js/contractIngration";

const contractAddress = "0xdD429aF938c08baaB357044729d5559FDA307f3d";

const Manage = () => {
  document.title = "Forge - Manage";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customSlider = createRef();

  const isNextFetch = useSelector(({ isNextFetch }) => isNextFetch);

  const [draftData, setDraftData] = useState([]);
  const [deployedData, setDeployedData] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [searchTxt, setSearchTxt] = useState("");
  const [pillsTab, setPillsTab] = useState("Launched");
  const [isMintPaused, setIsMintPaused] = useState(false);
  const [contract, setContract] = useState("");
  const [isArtReveled, setIsArtRevealed] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const initialize = async () => {
      const n = await initializeContract(contractAddress);
      setContract(n);
    };
    initialize();
  }, [contractAddress]);

  useEffect(() => {
    const getReveal = async () => {
      const n = await isRevealed();
      setIsArtRevealed(n);
    };
    getReveal();
  }, [contract]);

  const fetchData = async () => {
    try {
      dispatch(setLoader(true));
      const result = await ApiCall("GET", `/rest/forge`);
      const deployedDataRes = result
        ?.filter((item) => item.status === "payment_pending")
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      setDeployedData(deployedDataRes);
      setDraftData(result?.filter((item) => item.status === "payment_pending"));
      dispatch(setLoader(false));

      dispatch(
        setIsNextFetch({
          ...isNextFetch,
          [deployedDataRes[0]?.id]: true,
        })
      );

      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Failed to load. Please try again later.");
      console.log(error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      fetchData();
    }
  }, []);

  const filterData = deployedData.filter((item) => {
    return (item?.name).toLocaleLowerCase().search(searchTxt.toLocaleLowerCase()) >= 0;
  });

  const settings = {
    className: "center",
    centerMode: true,
    Infinity: false,
    infinite: false,
    arrows: false,
    centerPadding: "400px",
    slidesToShow: 1,
    draggable: false,
    beforeChange: (current, next) => setSliderIndex(next),
    afterChange: (current) => setSliderIndex(current),
    speed: 500,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 1600,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 1280,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: "30px",
        },
      },
    ],
  };

  useEffect(() => {
    if (filterData?.length === 0) {
      setSliderIndex(0);
    }
  }, [filterData, setSliderIndex]);

  const gotoNext = () => {
    customSlider.current.slickNext();
    dispatch(
      setIsNextFetch({
        ...isNextFetch,
        [filterData[sliderIndex + 1]?.id]: true,
      })
    );
  };

  const gotoPrev = () => {
    customSlider.current.slickPrev();
  };

  const handlePause = async (id) => {
    try {
      dispatch(setLoader(true));

      // web 3 pause mint
      const res = await togglePause();
      dispatch(setLoader(false));
      toast.success(`Mint has been ${isMintPaused ? "paused" : "resumed"}.`);
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      toast.error("Failed to pause. Please try again later.");
    }
  };

  const handleResume = async (id) => {
    try {
      dispatch(setLoader(true));
      await ApiCall("PATCH", `/rest/forge/${id}/pause/${true}`);
      fetchData();
      dispatch(setLoader(false));
      toast.success("Registration for active groups resumed.");
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      toast.error("Faled to resume. Please try again later.");
    }
  };

  useEffect(() => {
    const pauseToggle = async () => {
      const res = await pauseMint();
      setIsMintPaused(res);
    };
    pauseToggle();
  }, [contract, handlePause, handleResume]);

  const handleRevealArt = async (id) => {
    try {
      dispatch(setLoader(true));

      dispatch(setLoader(false));
      toast.success("Art Revealed’");
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      toast.error("Failed to reveal art. Please try again later.");
    }
  };

  const handleWithdraw = async (id) => {
    try {
      dispatch(setLoader(true));
      const res = await withdraw();
      console.log(res);
      dispatch(setLoader(false));
      toast.success("Withdrawn.");
    } catch (error) {
      console.log(error);
      dispatch(setLoader(false));
      toast.error("Failed to withdraw. Please try again later.");
    }
  };

  const gotoBasicForm = () => {
    localStorage.removeItem("pricingForm");
    localStorage.removeItem("basicForm");

    dispatch(setSaveDraftData(null));
    dispatch(setSavedFirstStep(null));
    dispatch(setCurrentFormStep(1));
    navigate("/launch");
  };

  return (
    <>
      <Header />
      <div className="comon-all-body hmepage-1 float-start w-100 mt-3">
        <div className="comon-div">
          <div className="container">
            <h1 className="main-haeding text-center text-white"> Manage Mints </h1>
            <p className="sub-haeding d-block mx-auto text-center text-white fs-22 futura-light">
              Manage launched and draft Mints. Edit groups, add/delete addresses, pick raffle winners and more
            </p>
          </div>
          <div className="my-md-5 my-4 mints-carousel">
            <div className="tabs-section">
              <div className="tab-content mb-md-5 mb-4" id="pills-tabContent">
                <div
                  className={`tab-pane fade ${pillsTab === "Launched" ? "active show" : ""}`}
                  id="pills-launched"
                  role="tabpanel"
                >
                  {(deployedData?.length === 0 || deployedData?.length === undefined) && pillsTab === "Launched" ? (
                    <>
                      <div className="container px-0">
                        <div className="mints-right-part draft-wrapper">
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <TabMenu pillsTab={pillsTab} setPillsTab={setPillsTab} />
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="manage-gen-mints-tab launch-sec d-block text-center">
                          <p className="mb-4">Go ahead and launch your first mint</p>
                          <button
                            onClick={gotoBasicForm}
                            className="btn next-btn mx-auto"
                            style={{ minWidth: "200px" }}
                          >
                            <span>Launch</span>
                            <span className="d-flex">
                              <img src={ArrowRight} alt="Icon" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="container px-0">
                        <div className="mints-right-part draft-wrapper">
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <TabMenu pillsTab={pillsTab} setPillsTab={setPillsTab} />
                            <ul className="d-flex align-items-center gap-2" style={{ height: 28 }}>
                              {sliderIndex !== 0 && filterData?.length !== 0 && (
                                <>
                                  <li className="slick-arrow-btn">
                                    <button
                                      onClick={() => {
                                        gotoPrev();
                                      }}
                                    >
                                      <FiChevronLeft />
                                    </button>
                                  </li>
                                </>
                              )}
                              <li className="search-allow">
                                <input
                                  type="text"
                                  value={searchTxt}
                                  onChange={(e) => setSearchTxt(e.target.value)}
                                  placeholder="Search Mint"
                                />
                                <button className="right-icon">
                                  <ImArrowRight2 />
                                </button>
                              </li>
                              {filterData?.length - `${filterData?.length === 0 ? 0 : 1}` !== sliderIndex &&
                                filterData?.length !== 0 && (
                                  <>
                                    <li className="slick-arrow-btn">
                                      <button
                                        onClick={() => {
                                          gotoNext();
                                        }}
                                      >
                                        <FiChevronRight />
                                      </button>
                                    </li>
                                  </>
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <Slider {...settings} ref={customSlider}>
                        {filterData?.length !== 0 ? (
                          filterData?.map((item, index) => (
                            <div key={index} className="px-2">
                              <div className="manage-gen-mints-tab">
                                <Launched
                                  dataItem={item}
                                  handlePause={handlePause}
                                  handleResume={handleResume}
                                  fetchData={fetchData}
                                  handleRevealArt={handleRevealArt}
                                  handleWithdraw={handleWithdraw}
                                  isMintPaused={isMintPaused}
                                  isArtReveled={isArtReveled}
                                  isSearchFetch={searchTxt ? index === 0 : false}
                                  isFound={filterData.length === 1}
                                />
                              </div>
                              <div className="manage-gen-mints-tab launch-sec">
                                <p>Looking to launch another Mint?</p>
                                <button onClick={gotoBasicForm} className="btn next-btn m-0">
                                  <span>Launch Now</span>
                                  <span className="d-flex">
                                    <img src={ArrowRight} alt="Icon" />
                                  </span>
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="px-2">
                            <div className="manage-gen-mints-tab text-center" style={{ background: "transparent" }}>
                              No data found
                            </div>
                          </div>
                        )}
                      </Slider>
                    </>
                  )}
                </div>
                <div
                  className={`tab-pane fade ${pillsTab === "Darft" ? "active show" : ""}`}
                  id="pills-drafts"
                  role="tabpanel"
                >
                  <>
                    <div className="container px-0">
                      <div className="mints-right-part draft-wrapper">
                        <div className="d-flex align-items-center gap-2 justify-content-between">
                          <TabMenu pillsTab={pillsTab} setPillsTab={setPillsTab} />
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      {draftData?.length === 0 ? (
                        <div className="manage-gen-mints-tab launch-sec d-block text-center">
                          <p className="mb-4">No drafts so far</p>
                          <button
                            onClick={gotoBasicForm}
                            className="btn next-btn mx-auto"
                            style={{ minWidth: "200px" }}
                          >
                            <span>Launch</span>
                            <span className="d-flex">
                              <img src={ArrowRight} alt="Icon" />
                            </span>
                          </button>
                        </div>
                      ) : (
                        <Darft draftData={draftData} fetchData={fetchData} />
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TabMenu = ({ pillsTab, setPillsTab }) => (
  <ul className="nav nav-pills align-items-center mx-0" id="pills-tab" role="tablist">
    <li className="nav-item" role="presentation">
      <button
        onClick={() => setPillsTab("Launched")}
        className={`nav-link ${pillsTab === "Launched" ? "active" : ""}`}
        id="pills-home-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-launched"
        type="button"
        role="tab"
      >
        Launched
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button
        onClick={() => setPillsTab("Darft")}
        className={`nav-link  ${pillsTab === "Darft" ? "active" : ""}`}
        id="pills-profile-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-drafts"
        type="button"
        role="tab"
      >
        Drafts
      </button>
    </li>
  </ul>
);

export default Manage;
