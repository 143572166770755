import axios from "axios";
import { toast } from "react-toastify";

export const API_AUTH_URL = process.env.REACT_APP_API_AUTH_URL || "https://8080auth.0xytocin.online";
export const API_FILES_URL = process.env.REACT_APP_API_FILES_URL || "https://8080files.0xytocin.online";
export const API_FORGE_URL = process.env.REACT_APP_API_FORGE_URL || "https://forge.0xytocin.online";
export const API_ALLOWLIST_URL = process.env.REACT_APP_API_ALLOWLIST_URL || "https://8080allowlist.0xytocin.online";

export const ApiCall = async (method = "GET", path, data = {}) => {
  const token = localStorage.getItem("token");
  const res = await axios({
    method,
    url: API_FORGE_URL + path,
    data,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res.data;
};

export const AllowlistApiCall = async (method = "GET", path, data = {}) => {
  const token = localStorage.getItem("token");
  const res = await axios({
    method,
    url: API_ALLOWLIST_URL + path,
    data,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return res.data;
};

export const ApiAuth = async (data) => {
  try {
    let resp = null;
    const token = localStorage.getItem("token");
    const res = await axios.post(API_AUTH_URL + "/rest/auth/login", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (res.data && res.status === 200) {
      resp = res.data;
    } else {
      throw new Error(res?.data?.message || "Auth api Error");
    }
    return resp;
  } catch (error) {
    if (error?.response?.data?.status === 500) {
      toast.error(
        <p>
          {`${error?.response?.data?.message} `}
          <a target="_blank" href="https://discord.gg/vwnEE7WKWd">
            Join Discord
          </a>
        </p>
      );
    } else {
      toast.error(error?.response?.data?.status?.message || "Something went wrong.");
    }
  }
};

export const ApiFileUpload = async (file, id) => {
  const fileFormData = new FormData();
  fileFormData.append("file", file, file.name);
  const token = localStorage.getItem("token");
  const res = await axios.post(`${API_FILES_URL}/rest/files/upload${id ? "/" + id : ""}`, fileFormData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  return res.data;
};

export const ApiGetFile = async (fileId) => {
  if (!fileId) return;
  const res = await axios.get(`${API_FILES_URL}/rest/files/getFileById/${fileId}`);

  return res.data;
};
